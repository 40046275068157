.contenedor-principal-item-slider{
    /* Box model */
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
    overflow-x: hidden;
    /* Grid */
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(14, 1fr);
    background-color: #ccc;
}

.contenedor-principal-item-slider .btn-login-inicio{
    position: absolute;
    right: 30px;
    top: 0;
    width: 146px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content:center;
    align-items:center;
    font-weight: bold;
    color: black;
    text-decoration: none;
}

.background-container {
    background-image: url('../../../../../assets/img/fondo.jpg'); 
    background-size: cover;
    background-position: center 5%; /* Movemos la imagen un poco más hacia arriba */
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(14, 1fr);
}

.contenedor-principal-item-slider img{
    opacity: .8;
}


.item-1{
    width: 85%;
    height: auto;
    transform: scaleX(-1);
    grid-column: 1/10;
    padding-right: 50px;
    padding-top: 30px;
    /* grid-row: 1/; */
    position: absolute;
}
.item-2{
    width: 90%;
    height: auto;
    grid-column: 13/-1;
    grid-row: 2/4;
    position: absolute;
    /* right: 0; */
}
.item-3{
    width: 100%;
    height: auto;
    grid-column: 8/14;
    grid-row: 6/8;
    position: absolute;
    opacity: 1 !important;
    filter: brightness(1.3);
    mix-blend-mode: multiply;
}
.item-4{
    width: 95%;
    height: auto;
    position: absolute;
    padding-top: 55px;
    grid-column: 1/9;
    grid-row: 8/-1;
    padding-left: 70px;
}
.item-5{
    width: 80%;
    height: auto;
    position: absolute;
    padding-left: 50px;
    /* right: 0; */
    grid-column: 13/-1;
    grid-row: 8/10;
}
.item-6{
    width: 100%;
    /* height: auto; */
    position: absolute;
    right: 0;
    grid-column: 0/-1;
    grid-row: 13/14;
    margin-top: -30px;
}

@media screen and (max-width: 950px) {
    .item-1{
        width: 95%;
    }
    .item-2{
        width: 95%;
        padding-top: 20px;
    }
    .item-3{
        grid-column: 8/14;
    }
    .item-4{
        width: 100%;
    }
    .item-5{
        width: 90%;
        padding-left: 10px;
    }
}

@media screen and (max-width: 767px) {
    .contenedor-principal-item-slider .btn-login-inicio{
        font-size: 1.5rem;
    }
    .item-1{
        width: 100%;
        left: -80px;
        padding: 0;
        grid-column: 1/20;
    }
    .item-2{
        width: 100%;
        padding: 0;
        grid-row: 9/-1;
        grid-column: 1/-1;
        right: -80px;
    }
    .item-3{
        grid-row: 7/8;
        grid-column: 6/16;
    }
    .item-4,
    .item-5{
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .contenedor-principal-item-slider .btn-login-inicio{
        font-size: 1.5rem;
    }
    .item-1{
        width: 80%;
        left: -80px;
        padding: 0;
        grid-column: 1/20;
    }
    .item-2{
        width: 80%;
        padding: 0;
        grid-row: 9/-1;
        grid-column: 1/-1;
        right: -80px;
    }
    .item-3{
        grid-row: 7/8;
        grid-column: 6/16;
    }
    .item-4,
    .item-5{
        display: none;
    }
}


@media screen and (max-width: 500px) {
    .item-1{
        width: 80%;
        left: -80px;
        padding: 0;
        grid-column: 1/20;
    }
    .item-2{
        width: 80%;
        padding: 0;
        grid-row: 9/-1;
        grid-column: 1/-1;
        right: -80px;
    }
    .item-3{
        grid-row: 6/7;
        grid-column: 6/16;
    }
}

@media screen and (min-width: 1600px) {
    .item-1{
        width: 80%;
        padding-right: 150px !important;
    }
    .item-2{
        width: 70%;
        padding-top: 20px;
    }
    .item-3{
        grid-column: 8/14;
    }
    .item-4{
        width: 85%;
        padding-left: 150px !important;
    }
    .item-5{
        width: 65%;
        padding-left: 10px;
    }
    .item-6{
        grid-row: 13/15;
    }
}

@media screen and (min-width: 1280px) {
    .item-3{
        grid-column: 9/13;
        grid-row: 7/8;
    }
}
